<template>
  <section
    v-if="showDynamicComponents && getMapSectionContent"
    class="section --no-padding-mobile --map"
  >
    <div class="wrapper">
      <h2 class="title" data-aos="fade-up">{{ getMapSectionContent.title }}</h2>

      <p class="subtitle" data-aos="fade-up">
        {{ getMapSectionContent.subtitle }}
      </p>
    </div>
    <div ref="map" class="map" data-aos="fade-up">
      <div class="map-container">
        <AppAnimatedMap />
      </div>
      <div class="wrapper">
        <AppCounters key="counters-map" :counters="getMapSectionContent.acfCounters.counterItem" />
      </div>
    </div>
    <div>
      <div data-aos-delay="100" data-aos-duration="550">
        <AppButton @click.native.stop="setCalendlyPopupVisibility(true)"
          >{{ getMapSectionContent.buttonContent }} <ArrowLinearIcon
        /></AppButton>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'InvestmentsMapSection',
  components: {
    AppAnimatedMap: () => import('@/components/common/AppAnimatedMap'),
    AppCounters: () => import('@/components/common/AppCounters'),
    AppButton: () => import('@/components/common/AppButton'),
    ArrowLinearIcon: () => import('@/components/icons/ArrowLinearIcon'),
  },
  props: {
    showDynamicComponents: {
      default: true,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters(['getMapSectionContent']),
  },
  methods: {
    ...mapActions(['setCalendlyPopupVisibility']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/components/common/sections/InvestmentsMapSection';
</style>
